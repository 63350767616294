import latinize from 'latinize';

export const cleanName = (prefix) => latinize(prefix)
  .toLowerCase()
  .replace(/[^a-z]/g, '');

export const cleanEmail = (prefix) => latinize(prefix)
  .toLowerCase()
  .replace(/[\s'‘]/g, '-')
  .replace(/[^a-z0-9.-]/g, '')
  .replace(/-[.-]+/g, '-')
  .replace(/\.[.-]+/g, '.')
  .replace(/^[.-]+/, '')
  .replace(/[.-]+$/, '');

export const isEasyEmail = (firstName, lastName) => {
  const email = latinize(`${firstName.trim()}.${lastName.trim()}`).toLowerCase();
  if (/[^a-z.]/.test(email)) return false;
  if (email.length > 20) return false;
  return true;
};

export const isPartOf = (part, whole) => {
  const clean = cleanEmail(whole).replace(/\./g, '-');
  const cleanAndOptional = clean.split('').join('?');
  if (!cleanAndOptional) return false;
  const regex = new RegExp(`^${cleanAndOptional}?$`);
  return regex.test(part);
};

export const isEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};

// lib
import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import queryString from 'query-string';
// src
import { API_URL } from 'config';
import EmailChecked from './states/EmailChecked';
import Error from './states/Error';
import Form from './states/Form';
import Loading from './states/Loading';
import Redirect from './states/Redirect';
import Saved from './states/Saved';

const Main = () => {
  const [content, setContent] = useState(<Loading />);

  const handleOk = () => {
    window.location = '/?saved';
  };

  const handleUnauthorized = () => {
    window.location = '/?error';
  };

  useEffect(() => {
    const {
      token, saved, error, checked,
    } = queryString.parse(window.location.search);
    if (saved !== undefined) {
      setContent(<Saved />);
    } else if (error !== undefined) {
      setContent(<Error />);
    } else if (checked !== undefined) {
      setContent(<EmailChecked />);
    } else if (!token) {
      setContent(<Redirect />);
    } else {
      const fetchData = async () => {
        try {
          const res = await fetch(`${API_URL}/student/getInfos/t-${token}/`);
          if (res.ok) {
            const body = await res.json();
            setContent(<Form
              token={token}
              handleOk={handleOk}
              handleUnauthorized={handleUnauthorized}
              {...body}
            />);
          } else {
            window.location = '/?error';
          }
        } catch (e) {
          window.location = '/?error';
        }
      };
      fetchData();
    }
  }, []);

  return (
    <main>
      <Container maxWidth="sm">
        { content }
      </Container>
    </main>
  );
};

export default Main;

import enLocale from './locales/en.json';
import frLocale from './locales/fr.json';

export default {
  en: {
    translation: enLocale,
  },
  fr: {
    translation: frLocale,
  },
};

// lib
import React from 'react';
import { useTranslation } from 'react-i18next';

const Component = () => {
  const { t } = useTranslation();
  setTimeout(() => {
    window.location = 'https://www.centralesupelec.fr/';
  }, 5000);

  return (
    <>
      <p>{t('redirect.p1')}</p>
      <p>{t('redirect.p2')}</p>
      <p>{t('redirect.p3')}</p>
      <p>{t('redirect.p4')}</p>
    </>
  );
};

export default Component;

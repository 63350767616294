// lib
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Send from "@material-ui/icons/Send";
import Focus from "@material-ui/icons/CenterFocusStrongOutlined";
import Warning from "@material-ui/icons/WarningRounded";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import LockOutlined from "@material-ui/icons/LockOutlined";
import { useTranslation } from "react-i18next";
import XRegExp from "xregexp";
// src
import { API_URL } from "config";
import { EMAIL_REGEX, EMAIL_SUFFIX } from "config/constants";
import { cleanEmail, cleanName, isEasyEmail, isPartOf, isEmpty } from "utils/format";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from "@material-ui/core";

const alphanum = XRegExp("^[\\pL\\pM-. ]+$");
const endsByNumber = /\d$/;

const validateFileSize = (files) => {
  const f = files[0];
  return f && f.size < 2000000; // 2MB
};

const ALLOWED_MIME_TYPES = ["image/gif", "image/jpeg", "image/png"];

const validateFileType = (files) => {
  const f = files[0];
  return f && ALLOWED_MIME_TYPES.includes(f.type);
};

const TITLES = [
  { value: "MR", key: "values.Mr" },
  { value: "MME", key: "values.Ms" },
];

const STATE = {
  LOCKED: 1,
  EDITABLE: 2,
  EDITING: 3,
  HIGHLIGHTED: 4,
  CHECKED: 5,
};

const Field = (props) => {
  const { name, stateMap, errors, handleEditToggle, t, ...rest } = props;

  const getIconComponent = (state) => {
    switch (state) {
      case STATE.LOCKED:
        return (
          <IconButton disabled className="button-edit">
            <LockOutlined />
          </IconButton>
        );
      case STATE.EDITABLE:
        return (
          <IconButton className="button-edit" onClick={handleEditToggle(name)} aria-label="edit">
            <Edit style={{ fontSize: 18 }} />
          </IconButton>
        );
      case STATE.EDITING:
        return <div className="blank-button" />;
      // case STATE.HIGHLIGHTED:
      //   return (
      //     <IconButton disabled className="button-edit" aria-label="edit">
      //       <ChevronLeft style={{ fontSize: 28 }} color="secondary" />
      //     </IconButton>
      //   );
      case STATE.CHECKED:
        return (
          <IconButton disabled className="button-edit">
            <Check style={{ color: "green" }} />
          </IconButton>
        );
      default:
        return <></>;
    }
  };
  const disabled =
    !errors[name] && [STATE.LOCKED, STATE.EDITABLE, STATE.CHECKED].includes(stateMap[name]);
  return (
    <>
      <TextField
        id={name}
        name={name}
        required
        disabled={disabled}
        label={t(`common.${name}`)}
        helperText={errors[name] && t(`errors.${errors[name].type}`)}
        fullWidth
        margin="dense"
        variant="outlined"
        {...rest}
      />
      {getIconComponent(stateMap[name])}
    </>
  );
};
Field.propTypes = {
  name: PropTypes.string.isRequired,
  stateMap: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleEditToggle: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const Form = ({
  token,
  handleOk,
  handleUnauthorized,
  title,
  lastName,
  firstName,
  dateOfBirth,
  csEmail,
  csEmailEditable,
  personalEmail,
  phoneCode,
  phoneNumber,
}) => {
  const {
    register,
    handleSubmit: routeSubmit,
    errors,
    getValues,
    setValue,
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      title,
      firstName,
      lastName,
      dateOfBirth,
      personalEmail,
      csEmail,
      phoneCode,
      phoneNumber,
    },
  });

  const [serverError, setServerError] = useState(false);
  // Duplicate depends on initial csEmail (server may know this is a duplicate from the beginning)
  const [duplicate, setDuplicate] = useState(endsByNumber.test(csEmail));
  const [sending, setSending] = useState(false);
  const [stateMap, setStateMap] = useState({
    firstName: firstName ? STATE.EDITABLE : STATE.HIGHLIGHTED,
    lastName: lastName ? STATE.EDITABLE : STATE.HIGHLIGHTED,
    dateOfBirth: dateOfBirth ? STATE.EDITABLE : STATE.HIGHLIGHTED,
    csEmail: csEmailEditable ? STATE.HIGHLIGHTED : STATE.LOCKED,
    personalEmail: personalEmail ? STATE.EDITABLE : STATE.HIGHLIGHTED,
    phoneNumber: phoneNumber ? STATE.EDITABLE : STATE.HIGHLIGHTED,
  });
  const [alumni, setAlumni] = useState(true);
  const [fondation, setFondation] = useState(true);
  const [cesal, setCesal] = useState(true);
  const [charteInformatique, setCharteInformatique] = useState(false);
  const [openCharte, setOpenCharte] = useState(false);

  const handleOpenCharte = () => {
    setOpenCharte(true);
  };

  const handleCloseCharte = () => {
    setOpenCharte(false);
  };
  const handleCharteAgree = () => {
    setOpenCharte(false);
    setCharteInformatique(true);
  };
  const { t } = useTranslation();

  const fileError = (error) => (error && error.type ? t(`errors.file.${error.type}`) : null);

  const handleEditToggle = (name) => () => {
    const newState = stateMap[name] === STATE.EDITING ? STATE.EDITABLE : STATE.EDITING;
    setStateMap({ ...stateMap, [name]: newState });
  };

  const handleNameChange = () => {
    const newCsEmail = `${cleanEmail(getValues("firstName"))}.${cleanEmail(getValues("lastName"))}`;
    setValue("csEmail", newCsEmail, true);
    setDuplicate(false);
    const easy = isEasyEmail(getValues("firstName"), getValues("lastName"));
    setStateMap({ ...stateMap, csEmail: easy ? STATE.LOCKED : STATE.HIGHLIGHTED });
  };

  const handleChangeFile = (e) => {
    e.persist();
    setValue("picture", e.target.files[0]);
  };

  const handleSubmit = async (data, e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(data).forEach(([name, value]) => {
      if (!["file"].includes(name)) formData.append(name, value);
    });
    formData.append("picture", data.file[0]);
    formData.append("alumni", alumni);
    formData.append("fondation", fondation);
    formData.append("cesal", cesal);
    formData.append("charteInformatique", charteInformatique);

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      setServerError(false);
      setSending(true);
      const res = await fetch(`${API_URL}/student/submit/t-${token}/`, {
        method: "POST",
        body: formData,
      });
      setSending(false);
      if (res.ok) {
        handleOk();
      } else if (res.status === 401) {
        handleUnauthorized();
      } else if (res.status === 422) {
        const { error } = await res.json();
        setError(error.field, error.type);
        if (error.type === "emailAlreadyTaken") {
          setDuplicate(true); // should not be set back to false once authorized
          setStateMap({ ...stateMap, csEmail: STATE.HIGHLIGHTED });
        }
      } else {
        setServerError(true);
        setTimeout(() => setServerError(false), 10000);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  let beforeSend;
  if (serverError) {
    beforeSend = <p className="error">{t("errors.server")}</p>;
  } else if (!isEmpty(errors)) {
    beforeSend = <p className="error">{t("infos.p5error")}</p>;
  } else if (sending) {
    beforeSend = <p className="warning">{t("infos.p5sending")}</p>;
  } else {
    beforeSend = <p>{t("infos.p5")}</p>;
  }

  const sendIcon = sending ? <CircularProgress /> : <Send />;

  // Personal email behavior
  const [waiting, setWaiting] = useState(false);
  const [checked, setChecked] = useState(false);
  const watchedPersonalEmail = watch("personalEmail");
  const personalEmailChanged = watchedPersonalEmail !== personalEmail;
  const handleCancelPersonalEmailChange = () => {
    setValue("personalEmail", personalEmail, true);
  };
  const handleVerifyPersonalEmailChange = async () => {
    const formData = new FormData();
    formData.append("personalEmail", watchedPersonalEmail);
    try {
      setWaiting(true);
      const res = await fetch(`${API_URL}/student/email/t-${token}/`, {
        method: "POST",
        body: formData,
      });
      if (!res.ok) {
        setServerError(true);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  const checkPersonalEmailChange = async () => {
    if (!waiting) return;
    try {
      const res = await fetch(`${API_URL}/student/email/status/t-${token}/`);
      if (res.ok || res.status === 404) {
        const body = await res.json();
        const newChecked = body && body.checked;
        if (newChecked) {
          setWaiting(false);
          setChecked(true);
          setStateMap({ ...stateMap, personalEmail: STATE.CHECKED });
        }
      } else {
        setServerError(true);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };
  useEffect(() => {
    document.addEventListener("visibilitychange", checkPersonalEmailChange);
    return () => {
      document.removeEventListener("visibilitychange", checkPersonalEmailChange);
    };
  });

  return (
    <form
      encType="multipart/form-data"
      action={`${API_URL}/student/submit/t-${token}/`}
      method="post"
      onSubmit={routeSubmit(handleSubmit)}
    >
      <input autoComplete="false" name="hidden" type="text" style={{ display: "none" }} />
      <p>{t("infos.p1")}</p>
      <p>{t("infos.p2")}</p>
      <p>{t("infos.p3")}</p>
      <hr></hr>
      <p style={{ fontStyle: "italic" }} dangerouslySetInnerHTML={{ __html: t("infos.dpo") }}></p>
      <hr></hr>
      <h3>{t("headings.1")}</h3>
      <div className="input-container">
        <Field
          name="title"
          className="input-small"
          select
          SelectProps={{
            native: true,
          }}
          disabled={stateMap.firstName === STATE.EDITABLE}
          inputRef={register({
            required: true,
          })}
          stateMap={stateMap}
          errors={errors}
          handleEditToggle={handleEditToggle}
          t={t}
        >
          {TITLES.map(({ key, value }) => (
            <option key={key} value={value}>
              {t(key)}
            </option>
          ))}
        </Field>
        <Field
          name="firstName"
          onChange={handleNameChange}
          inputRef={register({
            required: true,
            validate: {
              invalidCharacter: (v) => alphanum.test(v),
              tooLong50: (v) => v.length <= 50,
            },
          })}
          stateMap={stateMap}
          errors={errors}
          handleEditToggle={handleEditToggle}
          t={t}
        />
      </div>
      <div className="input-container">
        <Field
          name="lastName"
          onChange={handleNameChange}
          inputRef={register({
            required: true,
            validate: {
              invalidCharacter: (v) => alphanum.test(v),
              tooLong70: (v) => v.length <= 70,
            },
          })}
          stateMap={stateMap}
          errors={errors}
          handleEditToggle={handleEditToggle}
          t={t}
        />
      </div>
      <div className="input-container">
        <Field
          name="dateOfBirth"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({
            required: true,
          })}
          stateMap={stateMap}
          errors={errors}
          handleEditToggle={handleEditToggle}
          t={t}
        />
      </div>
      <h3>{t("headings.2")}</h3>
      <p>{t("infos.help2")}</p>
      <div className="input-container">
        <Field
          name="personalEmail"
          type="email"
          inputRef={register({
            required: true,
            validate: {
              invalidFormat: (v) => EMAIL_REGEX.test(v),
              tooLong150: (v) => v.length <= 150,
            },
          })}
          stateMap={stateMap}
          errors={errors}
          handleEditToggle={handleEditToggle}
          t={t}
        />
      </div>
      {!checked && personalEmailChanged && !waiting && (
        <div className="info-container">
          <p>{t("personalEmail.p1")}</p>
          <Button
            variant="contained"
            type="button"
            className="cancel"
            onClick={handleCancelPersonalEmailChange}
          >
            {t("actions.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="verify"
            type="button"
            onClick={handleVerifyPersonalEmailChange}
          >
            {t("actions.verify")}
            <span className="email-label">{watchedPersonalEmail}</span>
          </Button>
        </div>
      )}
      {!checked && personalEmailChanged && waiting && (
        <div className="info-container">
          <p>
            <span className="inline-icon">
              <Warning color="primary" />
            </span>
            {`${t("personalEmail.p2")} ${watchedPersonalEmail}`}
          </p>
        </div>
      )}
      <div className="input-container">
        <TextField
          id="phoneCode"
          name="phoneCode"
          className="input-small"
          disabled={stateMap.phoneNumber === STATE.EDITABLE}
          autoComplete="phoneCode"
          label={t("common.phoneCode")}
          required
          inputRef={register({
            required: true,
            validate: {
              tooLong10: (v) => v.length <= 10,
            },
          })}
          helperText={errors.phoneCode && t(`errors.${errors.phoneCode.type}`)}
          fullWidth
          margin="dense"
          variant="outlined"
        />
        <Field
          name="phoneNumber"
          type="tel"
          inputRef={register({
            required: true,
            validate: {
              tooLong20: (v) => v.length <= 20,
            },
          })}
          stateMap={stateMap}
          errors={errors}
          handleEditToggle={handleEditToggle}
          t={t}
        />
      </div>
      <div className="info-container">
        <p>{t("infos.pPhone")}</p>
      </div>
      <h3>{t("headings.3")}</h3>

      <div className="input-container">
        <Field
          name="csEmail"
          autoComplete="csEmail"
          inputRef={register({
            required: true,
            validate: {
              emailInvalidCharacter: (v) => !/[^a-z0-9.-]/.test(v),
              emailDots: (v) => !/\..*\./.test(v),
              emailDoubleSpecial: (v) => !/[.-]{2}/.test(v),
              emailEdgeSpecial: (v) => !/(^-|-$)/.test(v),
              emailFirstPartNoNumber: (v) => {
                const part = cleanEmail(v.split(".")[0]);
                return !/\d/.test(part);
              },
              emailFirstPartSignificant: (v) => {
                const part = cleanName(v.split(".")[0]);
                const { length } = cleanEmail(getValues("firstName"));
                return part.length >= Math.ceil(length / 2);
              },
              emailFirstPart: (v) => {
                const part = cleanEmail(v.split(".")[0]);
                return isPartOf(part, getValues("firstName"));
              },
              emailLastPartNumberIf: (v) => {
                const part = cleanEmail(v.split(".")[1]);
                const noNumber = part.replace(/\d/g, "");
                if (!part.startsWith(noNumber)) return false; // number not at the end
                return duplicate || !/\d/.test(part);
              },
              emailLastPartSignificant: (v) => {
                const part = cleanName(v.split(".")[1]);
                const { length } = cleanEmail(getValues("lastName"));
                return part.length >= Math.ceil(length / 2);
              },
              emailLastPart: (v) => {
                const part = cleanEmail(v.split(".")[1]).replace(/\d/g, "");
                return isPartOf(part, getValues("lastName"));
              },
              emailNumberIfDuplicate: (v) => !/\d/.test(v) || duplicate,
              tooLong150: (v) => v.length <= 150,
            },
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span className="inline-adornment">{EMAIL_SUFFIX}</span>
              </InputAdornment>
            ),
          }}
          stateMap={stateMap}
          errors={errors}
          handleEditToggle={handleEditToggle}
          t={t}
        />
      </div>
      {stateMap.csEmail !== STATE.LOCKED && (
        <div className="input-container">
          <div className="instructions">
            <p>{t("infos.pCS")}</p>
            <ul>
              <li>{t("infos.liCS1")}</li>
              <li>{t("infos.liCS2")}</li>
              {duplicate && <li className="highlight">{t("infos.liCSDuplicate")}</li>}
            </ul>
          </div>
        </div>
      )}
      <h3>{t("headings.4")}</h3>
      <p>
        {t("infos.p4")}
        <a
          href="http://mycs.centralesupelec.fr/fr/photos-identite"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://mycs.centralesupelec.fr/fr/photos-identite
        </a>
        .
      </p>
      <div className="input-container">
        <TextField
          id="file"
          name="file"
          type="file"
          required
          inputRef={register({
            validate: {
              size: validateFileSize,
              type: validateFileType,
            },
          })}
          onChange={handleChangeFile}
          error={Boolean(errors.file)}
          helperText={fileError(errors.file)}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Focus color="secondary" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <hr></hr>
      <p>
        <Checkbox
          id="alumni"
          name="alumni"
          checked={alumni}
          onChange={() => setAlumni(!alumni)}
          style={{ padding: 0, paddingRight: "0.5rem" }}
        />
        <label htmlFor="alumni" dangerouslySetInnerHTML={{ __html: t("infos.alumni") }}></label>
      </p>
      <p>
        <Checkbox
          id="fondation"
          name="fondation"
          checked={fondation}
          onChange={() => setFondation(!fondation)}
          style={{ padding: 0, paddingRight: "0.5rem" }}
        />
        <label
          htmlFor="fondation"
          dangerouslySetInnerHTML={{ __html: t("infos.fondation") }}
        ></label>
      </p>
      <p>
        <Checkbox
          id="cesal"
          name="cesal"
          checked={cesal}
          onChange={() => setCesal(!cesal)}
          style={{ padding: 0, paddingRight: "0.5rem" }}
        />
        <label htmlFor="cesal">{t("infos.cesal")}</label>
      </p>
      <hr></hr>
      <p>
        <Checkbox
          disabled={true}
          id="charteInformatique"
          name="charteInformatique"
          checked={charteInformatique}
          style={{ padding: 0, paddingRight: "0.5rem" }}
          onClick={handleOpenCharte}
        />
        <label onClick={handleOpenCharte}>
          <Link href="#" color="secondary">
            {t("infos.charteInformatique") + " *"}
          </Link>
        </label>
        <Dialog open={openCharte} onClose={handleCloseCharte} maxWidth={"xl"} fullWidth={true}>
          <DialogTitle id="alert-dialog-title">Charte Informatique</DialogTitle>
          <DialogContent>
            <iframe
              src="https://mycs.centralesupelec.fr/sites/myCS/files/inline-files/Charte-informatique_2.pdf"
              width="100%"
              height="600px"
              title="Charte Informatique"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCharteAgree} autoFocus>
              {t("infos.charteInformatique")}
            </Button>
          </DialogActions>
        </Dialog>
      </p>
      <hr></hr>

      {beforeSend}
      <Button
        disabled={sending || !charteInformatique}
        variant="contained"
        color="primary"
        type="submit"
        endIcon={sendIcon}
      >
        {t("actions.send")}
      </Button>
    </form>
  );
};

Form.propTypes = {
  token: PropTypes.string.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleUnauthorized: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  dateOfBirth: PropTypes.string.isRequired,
  csEmail: PropTypes.string.isRequired,
  personalEmail: PropTypes.string.isRequired,
  csEmailEditable: PropTypes.bool,
  title: PropTypes.string,
  phoneCode: PropTypes.string,
  phoneNumber: PropTypes.string,
};

Form.defaultProps = {
  csEmailEditable: false,
  title: "MR",
  phoneCode: "+33",
  phoneNumber: "",
};

export default Form;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
// src
import i18n, { languages as options } from 'i18n/index';
import { ReactComponent as Logo } from 'assets/logo.svg';

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  },
}));

const getLocaleText = (l) => (l === 'fr' ? 'Français' : 'English');
const getLocaleIcon = (l) => <i className={`flag flag-${l}`} />;

export default () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Logo className="logo" />
        <Typography variant="h6" className={classes.title}>
          Welcome
        </Typography>
        <Button onClick={handleClickListItem} color="inherit">
          {getLocaleIcon(selectedLanguage)}
          <ArrowDropDown />
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((language) => (
            <MenuItem
              key={language}
              selected={language === selectedLanguage}
              onClick={(event) => handleMenuItemClick(event, language)}
            >
              <span className="locale">
                {getLocaleIcon(language)}
                {getLocaleText(language)}
              </span>
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

// lib
import React from "react";

export default () => (
  <footer>
    <div className="container">
      <a href="https://www.centralesupelec.fr/" rel="noopener noreferrer" target="_blank">
        CentraleSupelec.fr
      </a>
    </div>
  </footer>
);

// lib
import 'react-app-polyfill/stable'; // among other for Edge compatibility
import 'whatwg-fetch'; // IE 11
import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// css
import CssBaseline from '@material-ui/core/CssBaseline';
import 'styles/index.scss';
// src
import { APP_VERSION } from 'config/constants';
import Root from 'components/Root';
import * as serviceWorker from 'serviceWorker';
import 'i18n/index';

console.log(`[welcome] version: ${APP_VERSION}`);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#847894',
    },
    secondary: {
      main: '#9c003c',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#2a313a',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Root />
  </ThemeProvider>,
  document.getElementById('app-container'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

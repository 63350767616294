// lib
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// src
import resources from './resources';

const detection = {
  order: ['navigator'],
  checkWhitelist: false,
  checkForSimilarInWhitelist: true,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    load: 'all',
    whitelist: ['en', 'fr'],
    detection,
    resources,
  });

export const languages = ['en', 'fr'];

export default i18n;

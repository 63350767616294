// lib
import React from 'react';
import { useTranslation } from 'react-i18next';

const Component = () => {
  const { t } = useTranslation();

  return (
    <>
      <p>{t('emailChecked.p1')}</p>
      <p>{t('emailChecked.p2')}</p>
    </>
  );
};

export default Component;

// lib
import React from 'react';
// src
import AppBar from './partials/AppBar';
import Footer from './partials/Footer';
import Main from './main/Main';
// assets

function Root() {
  return (
    <>
      <AppBar />
      <div id="page-container">
        <Main />
        <Footer />
      </div>
    </>
  );
}

export default Root;
